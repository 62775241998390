.MuiCard-root.service-card {
    width: 580px;
    max-width: 580px;
    text-align: left;
}

.MuiCard-root.service-card.PatentSearch,
.MuiCard-root.service-card.ProductAnalysis,
.MuiCard-root.service-card.upsell_analysis {
    background-image: linear-gradient(to bottom right, rgba(229, 228, 226, .1), rgba(229, 228, 226, .2), rgba(229, 228, 226, .5), rgba(229, 228, 226, .8));
}

.MuiCard-root.service-card.Both {
    background-image: linear-gradient(to bottom right, rgba(255, 204, 148, .1), rgba(255, 204, 148, .2), rgba(255, 204, 148, .5), rgba(255, 204, 148, .8));
}

.MuiCard-root.service-card.Supreme {
    background-image: linear-gradient(to bottom right, rgba(255, 215, 0, .1), rgba(255, 215, 0, .2), rgba(255, 215, 0, .5), rgba(255, 215, 0, .8));
}

.service-card .smaller {
    font-size: 0.9rem;
}

.logo {
    background-color: #FFFFFF;
    border-radius: 8px;
}

.logo img {
    vertical-align: middle;
    padding: 4px;
    height: 85px !important;
}

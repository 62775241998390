.task-card .MuiDivider-root {
    margin: 10px -24px;
}

.MuiCard-root.task-card {
    max-width: 400px;
}

.task-card .description {
    max-height: 200px;
    overflow-y: auto;
    text-align: left;
}

.analysis-dialog p {
    text-align: left;
}

.analysis-dialog table {
    margin: 0 -10px;
}

.analysis-dialog .underlined {
    text-decoration: underline !important;
    white-space: nowrap;
}

.analysis-dialog .signature {
    cursor: crosshair;
}

.light .analysis-dialog .signature {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #F0F8FF;
}

.dark .analysis-dialog .signature {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: #2A2A2A;
}

.analysis-dialog .MuiTextField-root {
    max-width: 800px !important;
}

.analysis-dialog .option {
    max-width: 800px !important;
}

.analysis-dialog .money-fields .MuiTextField-root {
    width: 300px !important;
}

.document-fields .dnd-file {
    height: 100px;
    width: 280px;
    margin-left: -15px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.light .document-fields .dnd-file {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #F0F8FF;
}

.dark .document-fields .dnd-file {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: #2A2A2A;
}

.document-fields .MuiChip-root,
.document-fields .MuiChip-deletable {
    max-width: 254px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

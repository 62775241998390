.task-page .card {
  width: 320px;
  vertical-align: top;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.task-page .complete svg {
  height: 22px;
  margin-right: 8px;
  margin-left: -5px;
}

.task-page .done {
  text-align: center;
  vertical-align: middle;
  display: block;
  height: 400px;
  width: 400px;
  line-height: 200px;
}

.analysis-results-dialog p {
    text-align: left;
}

.analysis-results-dialog table {
    margin: 0 -10px;
}

.analysis-results-dialog .underlined {
    text-decoration: underline !important;
    white-space: nowrap;
}

.analysis-results-dialog .signature {
    cursor: crosshair;
}

.light .analysis-results-dialog .signature {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #F0F8FF;
}

.dark .analysis-results-dialog .signature {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: #2A2A2A;
}

.MuiCard-root.document-card {
    width: 150px;
    max-width: 150px;
}

.document-card .preview {
    width: 140px;
    max-width: 140px;
    max-height: 140px;
    margin: 5px;
}

.document-card .num {
    margin-top: 20px;
    margin-bottom: 0;
}

.document-card .action {
    margin-top: 0;
}

.document-card .loading {
    margin-top: 10px;
    margin-bottom: 5px;
}

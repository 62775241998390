.search-agreement-card {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto !important;
}

.MuiCard-root.search-agreement-card {
    max-width: 420px;
}

.search-agreement-card .MuiDivider-root {
    margin: 10px -24px;
}

.search-agreement-card .question,
.search-agreement-card .answer {
    text-align: left;
}

.search-agreement-card .question {
    font-weight: bold;
    display: block;
}

.documents-card .MuiDivider-root {
    margin: 10px -24px;
}

.documents-card td {
    padding-right: 0;
}

.documents-card .timestamp {
    opacity: 0.5;
    white-space: nowrap;
}

.MuiCard-root.documents-card {
    max-width: 600px;
}

.documents-card .MuiLink-root {
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    display: block;
    max-width: 185px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.help-card .MuiDivider-root {
    margin: 10px -24px;
}

.help-card .timestamp {
    opacity: 0.5;
}

.MuiCard-root.help-card {
    max-width: 400px;
}

.header {
    height: 56px;
    top: 0;
    bottom: auto;
    flex-grow: 1;
}

.header .spacer {
    flex-grow: 1;
    text-align: left;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header .user-avatar td.left {
    padding-right: 10px;
}

.header .current-account-name {
    margin-left: 10px;
}

.header .current-account-logo,
.header .current-account-name,
.header .logged-in-user {
    cursor: pointer;
}

.header .custom img,
.header .custom svg {
    height: 56px;
}

.MuiCard-root.bundle-card {
    width: 580px;
    max-width: 580px;
    text-align: left;
}

.MuiCard-root.bundle-card.Bronze {
    background-image: linear-gradient(to bottom right, rgba(169, 113, 66, .1), rgba(169, 113, 66, .2), rgba(169, 113, 66, .5), rgba(169, 113, 66, .8));
}

.MuiCard-root.bundle-card.Silver {
    background-image: linear-gradient(to bottom right, rgba(192, 192, 192, .1), rgba(192, 192, 192, .2), rgba(192, 192, 192, .5), rgba(192, 192, 192, .8));
}

.MuiCard-root.bundle-card.Gold {
    background-image: linear-gradient(to bottom right, rgba(215, 190, 105, .1), rgba(215, 190, 105, .2), rgba(215, 190, 105, .5), rgba(215, 190, 105, .8));
}

.bundle-card svg {
    float: left;
    margin-right: 10px;
}

.bundle-card svg.Bronze {
    color: #A97142;
}

.bundle-card svg.Silver {
    color: #808080;
}

.bundle-card svg.Gold {
    color: #D7BE69;
}
